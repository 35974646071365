<template>
  <div>
    <navBar>
      <div class="pages">
        <header class="mt40">
          <div class="flex flex-between">
            <div class="title">
              Número de PQR:
              <span style="font-size: 20px">{{ detail.id }}</span>
            </div>
            <div class="status">{{ orderStatus[detail.status] }}</div>
          </div>
          <div class="label plr10 mt10 mr10">{{ orderType[detail.type] }}</div>
          <span>
            {{
              detail.title || detail.orderAftersale.orderGoodsDetail.goodsName
            }}
          </span>
        </header>
        <div class="title mt20">Detalles de la PQR</div>
        <main class="mt20" v-if="detail.type === 'BARTER'">
          <div class="flex">
            <div class="flex flex-column" style="width: 50%">
              <div class="main-title">
                Tiempo de aplicación:
                <span style="color: #000000" class="ml10">
                  {{ detail.createdTime }}
                </span>
              </div>
              <div class="main-title mtb15">
                El Import del reembolso:
                <span style="color: #000000" class="ml10">
                  COP:{{
                    (detail.orderAftersale.orderGoodsDetail.buyPriceOriginal -
                      detail.orderAftersale.orderGoodsDetail.buyPriceExchange)
                      | filterPrice
                  }}
                </span>
              </div>
              <div class="main-title">
                El tiempo de la solicitud:
                <span style="color: #000000" class="ml10">
                  {{ detail.orderAftersale.applyTime }}
                </span>
              </div>
            </div>
            <div class="flex flex-column">
              <div class="main-title mb15">
                Tipo de PQR:
                <span style="color: #000000" class="ml10">
                  {{ orderType[detail.type] }}
                </span>
              </div>
              <div class="main-title">
                La razon del reembolso:
                <span style="color: #000000" class="ml10">
                  {{ detail.orderAftersale.reasonContent }}
                </span>
              </div>
            </div>
          </div>
          <div class="mt20 main-shop">
            <div v-for="(item,index) in detail.orderAftersale.orderGoodObjList" :key="index" class="mb-10px">
              <card-horizontal
                :moreVisible="true"
                :detailInfo="true"
                :goodsInfo="item"
              ></card-horizontal>
            </div>
          </div>
          <div class="main-cause mt20">
            observaciones:
            <span>{{ detail.orderAftersale.additionalContent }}</span>
          </div>
          <div class="mt20" v-if="detail.imageUrl.length">
            <div
              class="main-img flex align-center flex-center"
              v-for="(img, index) in detail.imageUrl"
              :key="index + 'img'"
            >
              <img :src="img | filterPicture" alt="" />
            </div>
          </div>
        </main>
        <main v-else class="mt20">
          <div class="main-title">
            Tiempo de aplicación:
            <span style="color: #000000" class="ml10">
              {{ detail.createdTime }}
            </span>
          </div>
          <div class="main-title mtb10">
            Tipo de PQR:
            <span style="color: #000000" class="ml10">
              {{ orderType[detail.type] }}
            </span>
          </div>
          <div class="main-title">
            Título de la PQR:
            <span style="color: #000000" class="ml10">{{ detail.title }}</span>
          </div>
          <div class="main-title mtb10">
            Descripción detallada:
            <span style="color: #000000" class="ml10">
              {{ detail.details }}
            </span>
          </div>
        </main>
        <el-divider></el-divider>
        <footer v-if="replyList.length">
          <div class="title mt20">Registro de procesamiento</div>
          <div v-for="(contentItem, index) in replyList" :key="index">
            <div class="footer-content plr10 ptb10 mtb5">
              <div class="flex flex-between">
                <div class="title">
                  {{
                    contentItem.customerServiceEmployee.employeeName ||
                    contentItem.memberDetail.companyName
                  }}
                </div>
                <div class="time">{{ contentItem.createdTime }}</div>
              </div>
              <div class="mtb10">{{ contentItem.content }}</div>
              <div class="mt20" v-if="contentItem.imageUrl.length">
                <div class="main-img flex align-center flex-center">
                  <img :src="contentItem.imageUrl | filterPicture" alt="" />
                </div>
              </div>
            </div>
          </div>
        </footer>
        <el-divider v-if="replyList.length"></el-divider>
        <footer v-if="detail.workOrderEvaluateList">
          <div class="title mt20">Evaluación</div>
          <div
            v-for="(item, index) in detail.workOrderEvaluateList"
            :key="index"
          >
            <div class="footer-content plr10 ptb10 mtb5">
              <div class="flex flex-between">
                <div class="title" style="width: 700px">{{ item.content }}</div>
                <div class="time">{{ item.createdTime }}</div>
              </div>
              <div class="label plr10">{{ item.satisfaction }}</div>
              <div class="mt20" v-if="item.imageUrl">
                <div class="main-img flex align-center flex-center">
                  <img :src="item.imageUrl | filterPicture" alt="" />
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div class="flex flex-end ptb30 mlr30 mt20">
          <div
            v-if="detail.status === 'PROCESSING'"
            class="btn flex flex-center align-center mr10"
            @click="toReply"
          >
            Respuesta
          </div>
          <reply
            :show="replyDialog"
            @handleClose="replyDialog = false"
            @replyComfirm="replyComfirm"
          ></reply>
          <div
            v-if="detail.status === 'PROCESSING'"
            class="btn mr10 flex flex-center align-center"
            @click="endingOrder"
          >
            Resuelto
          </div>
          <comfirm-btn
            :title="title"
            @cancel="comfirmDialog = false"
            :show="comfirmDialog"
            @comfirm="comfirm"
          ></comfirm-btn>
          <div
            v-if="
              ['COMPLETED', 'EVALUATED', 'PROCESSING'].includes(detail.status)
            "
            class="btn flex-center flex align-center"
            style="color: #8acf66; border: 0.02rem solid #8acf66"
            @click="toEvaluation"
          >
            Evaluación
          </div>
          <evaluation
            :show="evaluationDialog"
            @handleClose="evaluationDialog = false"
            @evaluationComfirm="evaluationComfirm"
          ></evaluation>
        </div>
      </div>
    </navBar>
  </div>
</template>

<script>
import ComfirmBtn from '../../../components/comfirmBtn.vue'
import navBar from '../../../components/navBar/index.vue'
import CardHorizontal from '../../../components/product/card-horizontal.vue'
import Evaluation from './components/evaluation.vue'
import Reply from './components/reply.vue'
export default {
  components: {
    navBar,
    CardHorizontal,
    ComfirmBtn,
    Reply,
    Evaluation
  },
  data() {
    return {
      replyDialog: false,
      comfirmDialog: false,
      evaluationDialog: false,
      title: 'Ya sea para finalizar la PQR',
      replyList: [],
      detail: '',
      orderStatus: {
        UNTREATED: 'Pendiente',
        CANCELLED: 'Cancelado',
        PROCESSING: 'Procesando',
        COMPLETED: 'terminado',
        EVALUATED: 'Evaluado'
      },
      orderType: {
        CONSULTING: 'consultar',
        COMPLAINT: 'Sugerencias',
        BARTER: 'Regreso'
      }
    }
  },
  created() {
    this.$route.params.detail &&
      localStorage.setItem(
        'orderDetail',
        JSON.stringify(this.$route.params.detail)
      )
    setTimeout(() => {
      this.detail = JSON.parse(localStorage.getItem('orderDetail'))
      console.log('orderDetail', this.$route.params.detail, this.detail)
      this.detail.imageUrl = this.detail.imageUrl
        ? JSON.parse(this.detail.imageUrl)
        : []
      this.getReplyList()
      console.log('id', this.detail)
    }, 100)
  },
  methods: {
    getReplyList() {
      let data = {
        workOrderId: this.detail.id,
        pageNo: 0,
        pageSize: 100
      }
      this.$axios.post('/workOrderReply/list', data).then((res) => {
        this.replyList = res.data.result.rows.map((item) => {
          item.imageUrl = item.imageUrl ? JSON.parse(item.imageUrl) : ''
          return item
        })

        console.log('回复列表', this.replyList)
      })
    },
    toReply() {
      this.replyDialog = true
    },
    endingOrder() {
      this.comfirmDialog = true
    },
    comfirm() {
      console.log('comfirm')
      this.comfirmDialog = false
      let data = {
        id: this.detail.id
      }
      this.$axios.post('/workOrder/endWordOrder', data).then((res) => {
        setTimeout(() => {
          this.$router.push({ path: '/workOrderList', name: 'workOrderList' })
        }, 500)
      })
      this.$message({
        type: 'success',
        message: 'éxito!'
      })
    },
    toEvaluation() {
      this.evaluationDialog = true
    },
    replyComfirm(val) {
      val.workOrderId = this.detail.id
      console.log('val', val)
      this.$axios.post('/workOrderReply/add', val).then((res) => {
        this.$message.success('éxito!')
        this.replyDialog = false
        this.getReplyList()
      })
    },
    evaluationComfirm(val) {
      val.workOrderId = this.detail.id
      console.log('val', val)
      this.$axios.post('/workOrderEvaluate/add', val).then((res) => {
        this.$message.success('éxito!')
        this.evaluationDialog = false
        this.$axios.post('/workOrder/detail/' + this.detail.id).then((res) => {
          console.log('res', res)
          this.detail = res.data.result
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.pages {
  margin: auto;
  width: 1200px;
}
.btn {
  cursor: pointer;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 36px;
  width: 165px;
  height: 52px;
  border-radius: 26px;
  border: 2px solid #979797;
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 30px;
}
.label {
  display: inline-block;
  width: auto;
  height: 18px;
  background: #8acf66;
  border-radius: 9px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 18px;
}
header {
  background: #e7e7e7;
  padding: 16px;
  .status {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #d43a57;
    line-height: 21px;
  }

  .title {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #282828;
    line-height: 24px;
  }
}
.main-title {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #606060;
  line-height: 20px;
}
.main-shop {
  padding: 20px;
  height: auto;
  background: #f9fbf9;
  border: 1px solid #e7e7e7;
}
.main-cause {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #606060;
  line-height: 20px;
}
.main-img {
  width: 114px;
  height: 114px;
  background: #f9fbf9;
  img {
    width: 84px;
    height: 84px;
  }
}
.footer-content {
  background: #f9fbf9;
  border: 1px solid #e7e7e7;
  height: auto;
  .time {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #979797;
    line-height: 24px;
  }
}
</style>
