<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" @close="handleClose" width="400px">
      <div>
        <h3>Evaluación</h3>
        <div class="evaluation-title mb10 mt20">Satisfacción del servicio</div>
        <div class="evaluation-select">
          <el-select v-model="form.satisfaction">
            <el-option
              v-for="(item, index) in columns"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="evaluation-title mb10 mt20">Descripción detallada</div>
        <el-input :rows="5" type="textarea" v-model="form.content" />
      </div>
      <div slot="footer" class="dialog-footer flex flex-center">
        <div @click="dialogVisible = false" class="mr10 flex flex-center">
          Cancelar
        </div>
        <div
          style="background: #8acf66; color: #ffffff"
          class="flex flex-center"
          @click="evaluationComfirm"
        >
          Seguro
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ['show'],

  data() {
    return {
      dialogVisible: false,
      form: {
        content: '',
        satisfaction: ''
      },
      empty: {},
      columns: [
        { label: 'Muy Satisfecho', value: 'PERFECT_CONTENTMENT' },
        { label: 'satisfacer', value: 'SATISFIED' },
        { label: 'generalmente', value: 'COMMONLY' },
        { label: 'Insatisfecho', value: 'DISSATISFIED' }
      ]
    }
  },
  watch: {
    show(val) {
      this.dialogVisible = val
      console.log('show', val)
    }
  },
  methods: {
    handleClose() {
      this.$emit('handleClose')
      console.log('关闭了')
      this.form = this.$deepCopy(this.empty)
    },
    evaluationComfirm() {
      let params = this.form
      this.$emit('evaluationComfirm', params)
    }
  }
}
</script>

<style lang="scss" scoped>
h3 {
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 22px;
}
.evaluation-title {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #282828;
  line-height: 22px;
}
.imgVisible {
  display: inline-block;
  width: 100px;
  margin: 10px 20px 0 0;
  img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    display: block;
  }
}

// 删除图标
.col-delete {
  position: relative;
  .el-icon-error {
    display: none;
    top: -7px;
    right: -7px;
    position: absolute;
    color: #999;
    font-size: 20px;
    cursor: pointer;
    &:hover {
      color: #333;
    }
  }
  &:hover {
    .el-icon-error {
      display: block;
    }
  }
}
.dialog-footer {
  div {
    cursor: pointer;
    width: 158px;
    height: 48px;

    background: #f9fbf9;
    border-radius: 28px;
    border: 1px solid #e7e7e7;
    line-height: 48px;
  }
}
</style>
<style lang="scss">
.evaluation-select .el-select .el-input__inner {
  width: 350px !important;
}
</style>
