<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" @close="handleClose" width="400px">
      <div>
        <div class="reply-title mb10">Responder contenido</div>
        <el-input v-model="form.content" type="textarea" :rows="5"></el-input>
        <div class="mt10">
          <uploadImg
            folder="head"
            type="images"
            @listenPostImage="postImage($event)"
            :multiple="true"
          />
          <template v-if="uploadList">
            <div class="flex flex-wrap">
              <div
                class="imgVisible col-delete"
                v-for="(imgItem, imgIndex) in uploadList"
                :key="imgIndex"
              >
                <img :src="imgItem | filterPicture" alt="" />
                <i
                  @click="delImg(uploadList, imgIndex)"
                  class="el-icon-error"
                />
              </div>
            </div>
          </template>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="replyComfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import uploadImg from '../../../../components/upload/image.vue'
export default {
  props: ['show'],
  components: {
    uploadImg
  },
  data() {
    return {
      dialogVisible: false,
      uploadList: [],
      form: {
        content: '',
        imageUrl: ''
      }
    }
  },
  watch: {
    show(val) {
      this.dialogVisible = val
      console.log('show', val)
    }
  },
  methods: {
    postImage(url) {
      console.log(url)
      this.uploadList.push(url)
      this.$forceUpdate()
      console.log(this.uploadList)
    },
    handleClose() {
      this.$emit('handleClose')
      console.log('关闭了')
    },
    replyComfirm() {
      this.form.imageUrl = this.uploadList
      let params = this.form
      this.$emit('replyComfirm', params)
    }
  }
}
</script>

<style lang="scss" scoped>
.reply-title {
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 22px;
}
.imgVisible {
  display: inline-block;
  width: 100px;
  margin: 10px 20px 0 0;
  img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    display: block;
  }
}

// 删除图标
.col-delete {
  position: relative;
  .el-icon-error {
    display: none;
    top: -7px;
    right: -7px;
    position: absolute;
    color: #999;
    font-size: 20px;
    cursor: pointer;
    &:hover {
      color: #333;
    }
  }
  &:hover {
    .el-icon-error {
      display: block;
    }
  }
}
</style>
